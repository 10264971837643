import React, { FC } from "react"
import { withPrefix } from "gatsby"

const ThesisIntroducingDefenseLogo: FC = () => (
  <div className="thesis-defense-introducing-logo" aria-label="INTRODUCING">
    <img src={withPrefix("/images/thesis-defense.svg")} alt="thesis-defense" />
  </div>
)

export default ThesisIntroducingDefenseLogo
