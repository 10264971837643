import React, { FC, ReactNode } from "react"
import { Helmet } from "react-helmet"
import { graphql, withPrefix } from "gatsby"
import "../css/defense.css"
import LineDivider, { LineDividerIcon } from "../components/LineDivider"
import TeamMember, { TeamMemberProps } from "../components/TeamMember"
import Head from "../components/Head"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Embellishment4, Embellishment12 } from "../components/Embellishments"
import ThesisIntroducingDefenseLogo from "../components/ThesisIntroducingDefenseLogo"

type DefensePageProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        hero: {
          title: string
          richBody: { html: string }
        }
        contactUs: {
          title: string
          cta: {
            label: string
            url: string
          }[]
        }
        aboutUs: {
          what: WhatSubSectionProps
          who: WhoSubSectionProps
        }
      }
    }
  }
}

type WhatSubSectionProps = {
  title: string
  richBody: { html: string }
  stack: {
    title: string
    items: { label: string }[]
  }[]
}

type WhoSubSectionProps = {
  title: string
  richBody: { html: string }
  team: {
    title: string
    members: TeamMemberProps[]
  }
}

function HeroSection({ title }: { title: string }) {
  return (
    <section className="hero padding---subpage-hero">
      <header className="hero__header">
        <ThesisIntroducingDefenseLogo />
      </header>
      <h2 className="hero__title">{title}</h2>
    </section>
  )
}

function ContactUsSection({
  title,
  cta,
}: {
  title: string
  cta: { label: string; url: string }[]
}) {
  const cta1 = cta[0]
  const cta2 = cta[1]

  return (
    <section className="contact-us" id="contact-us">
      <Embellishment4 />
      <h2>{title}</h2>
      <div className="contact-us__cta">
        <a
          href={cta1.url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--link "
        >
          {cta1.label}
        </a>
        <span>or email us</span>
        <a href={cta2.url} target="_blank" rel="noopener noreferrer">
          {cta2.label}
        </a>
      </div>
    </section>
  )
}

const SubHeroSection: FC<{ body: string }> = ({ body }) => (
  <section className="subhero background---black">
    <div
      className="subhero__content"
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </section>
)

const AboutUsSection: FC<{ children: ReactNode }> = ({ children }) => (
  <section className="about-us position---relative" id="about-us">
    <Embellishment12 />
    <LineDivider>
      <LineDividerIcon
        alt="thesis-defense-logo"
        src={withPrefix("/images/icons/defense-logo.svg")}
      />
    </LineDivider>
    {children}
    <LineDivider>
      <LineDividerIcon
        alt="thesis-defense-logo"
        src={withPrefix("/images/icons/defense-logo.svg")}
      />
    </LineDivider>
  </section>
)

const WhatSubSection: FC<WhatSubSectionProps> = ({
  title,
  richBody,
  stack,
}) => (
  <section className="about-us__section">
    <h3 className="section__title">{title}</h3>
    <div
      className="section__content"
      dangerouslySetInnerHTML={{ __html: richBody.html }}
    />
    <div className="section__stack">
      {stack.map(({ title: stackTitle, items }) => (
        <ul key={stackTitle} aria-label={stackTitle}>
          {items.map(({ label }) => (
            <li key={label}>{label}</li>
          ))}
        </ul>
      ))}
    </div>
  </section>
)

const WhoSubSection: FC<WhoSubSectionProps> = ({ title, richBody, team }) => (
  <section className="about-us__section" id="who-sub-section">
    <h3 className="section__title">{title}</h3>
    <div
      className="section__content"
      dangerouslySetInnerHTML={{ __html: richBody.html }}
    />

    <section className="section__team-members">
      <h6 className="h6 label invert spacing---lg">{team.title}</h6>
      <div className="team-members" id="team">
        {team.members.map((member) => (
          <TeamMember
            key={`${member.name}-${member.role}`}
            name={member.name}
            role={member.role}
            bio={member.bio}
            socials={member.socials}
            languages={member.languages}
            expertise={member.expertise}
            photo={member.photo}
          />
        ))}
      </div>
    </section>
  </section>
)

function FooterSection() {
  return (
    <section className="defense-footer">
      <img
        src={withPrefix("/images/thesis-defense.svg")}
        alt="thesis-defense"
      />
    </section>
  )
}

function DefensePage(props: DefensePageProps) {
  const {
    data: { markdownRemark },
  } = props

  const {
    frontmatter: { title, hero, contactUs, aboutUs },
  } = markdownRemark

  const { what, who } = aboutUs
  return (
    <div className="text---white background---black">
      <Head />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="background---defense">
        <Header hideEmbellishment />
        <HeroSection title={hero.title} />
      </div>
      <ContactUsSection title={contactUs.title} cta={contactUs.cta} />
      <SubHeroSection body={hero.richBody.html} />
      <AboutUsSection>
        <WhatSubSection
          title={what.title}
          richBody={what.richBody}
          stack={what.stack}
        />
        <LineDivider />
        <WhoSubSection
          title={who.title}
          richBody={who.richBody}
          team={who.team}
        />
      </AboutUsSection>
      <ContactUsSection title={contactUs.title} cta={contactUs.cta} />
      <FooterSection />
      <Footer />
    </div>
  )
}

export default DefensePage

export const query = graphql`
  query DefensePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          title
          richBody {
            html
          }
        }
        contactUs {
          title
          cta {
            label
            url
          }
        }
        aboutUs {
          what {
            title
            richBody {
              html
            }
            stack {
              title
              items {
                label
              }
            }
          }
          who {
            title
            richBody {
              html
            }
            team {
              title
              members {
                name
                role
                bio {
                  html
                }
                languages
                expertise
                socials {
                  url
                  label
                }
                photo {
                  image {
                    relativePath
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`
